import React from 'react';
import citricAcid from '../assets/citric acid.jpg';
import benz from '../assets/Benz.jpg';
import formalin from '../assets/drum.webp';
import belerang from '../assets/belerangkotak.jpg';
import { Link } from 'react-router-dom';

const ProductCard = () => {
  const slides = [
    {
      name: "FORMALIN",
      image: formalin,
      link: "/formalin"
    },
    {
      name: "BELERANG",
      image: belerang,
      link: "/belerang"
    },
    {
      name: "CITRIC ACID MONOHYDRATE",
      image: citricAcid,
      link: 'citric'
    },
    {
      name: "BENZALKONIUM CHLORIDE",
      image: benz,
      link: "/benzalkonium"
    },
  ];

  return (
    <div>
        <div className='grid lg:grid-cols-4 grid-cols-2 gap-7 lg:gap-16 mt-6'>
            {slides.map((value) => (
                <div className='flex flex-col items-center'>
                    <div className='aspect-square rounded-lg overflow-hidden'>
                      <Link to={value.link}>
                          <img 
                            src={value.image}   
                            className='object-cover w-full h-full hover:scale-125 transition duration-200 hover:opacity-80'
                          />
                      </Link>
                    </div>
                    <Link to={value.link} className='font-sairaCondense text-white text-md md:text-4xl my-3 hover:text-yellow-200 transition duration-150'>
                      <button>{value.name}</button>
                    </Link>            
                </div>
            ))}
        </div>
    </div>
  );
};

export default ProductCard;
